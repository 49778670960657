import './GenCon22.css'
import gen22cov from '../../../pics/gen22cov.jpg'
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player';

export default function GenCon22(){

    return(
        <body className='GC22'>
            <div className='GC22topBorder' />
            <h1>Gen Con 2022</h1>
            <p>Gen Con 2022 was a unique one because we did not have a major release at the convention to build our marketing around. The only new product we had to sell was the <a href='https://czechgames.com/en/galaxy-trucker-keep-on-trucking/' rel='noreferrer'> Galaxy Trucker: Keep on Trucking expansion</ a>. However, due to one of the other co-sponsors dropping out, this year we were one of only two major sponsors of the show, leading to us having a bigger exhibit room and more locations available to us for advertising.</p>
            <p>As we didn't have a big release, I made the call to focus our convention on increasing our name recognition. Many people know games like Codenames, Lost Ruins of Arnak, and Galaxy Trucker; but not nearly as many knew that CGE were the people behind many of their favorite games.</p>
            <p>My first step for this was securing a new advertising location for us with 3 large banners right by the main entrance and behind the very popular "Cardhalla" atraction:</p>
            <div className="GC2pic">
                <div className='GCpic1o2' >
                    <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/GC22+Banners+Above+Cardhalla+1.png' alt='Banners at Gen Con 2022 entrance' />
                </div>
                <div className='GCpic1o2' >
                    <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/Cardhalla+with+banners+1.png' alt='Cardhalla with CGE banners. Credit to @role_dice on Twitter' />
                </div>
            </div>
            <p>These banners featured 3 of CGE's most popular titles help build the connection between them in people's mind and increase brand awareness of CGE. </p>
            <p>Gen Con also makes use of the field of Lucas Oil Stadium. I worked with Gen Con to open up the Jumbo-Tron for exhibitors to use for advertising:</p>
            <div className="GC1pic">
                <img src={gen22cov} alt='The Jumbotron at the Luc' />
            </ div>
            <p>The final new initiative we ran was a scanvenger hunt, were attendees could find clues hidden around the convention to help them solve puzzels in a web app with the chance to win games once completed. At registration every attendee receives a bag to put games and swag in, and we used these to advertise the hunt:</p>
            <div className="GC1pic">
                <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/ARgcBag+1.png' alt='Scavenger hunt 2022 bags' />
            </div>
            <p>We also focused on our two upcoming big-box releases "Starship Captains" and "Deal With the Devil". I trained our demo staff to run previews for both titles which recieved a warm reception at the show, including Deal With the Devil winning the Gaming Trend 'Best of Show' award. </p>
            <div className="GC2pic">
                <div className='GCpic1o2' >
                    <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/DWD+and+award.png' alt='Deal With the Devil and award' />
                </ div>
                <div className='GCpic1o2' >
                    <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/SC+in+media+room.png' alt='Starship Captains being shown in our media room.' />
                </ div>
            </ div>
            <p>Along side these efforts we featured an improved Giant Galaxy Trucker to promote the expansion, as well as improvements to Giant Pictomania to further my Giant Games project I introduced the prior year</p>
            <div className="GC2pic">
                <div className='GCpic1o2' >
                    <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/Giant+GT+die+roll.png' alt='Giant GT' />
                </ div>
                <div className='GCpic1o2' >
                    <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/Giant+PM+reveal.png' alt='Giant PM' />
                </ div>
            </div>
            <p>We also ran site book and coupon book adds, ran an extensive demo room, and had other advertising around the show. You can see more highlights in the slideshow below:</p>
            <div className='GCslide'>
                <Carousel fade>
                    <Carousel.Item>
                        <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/CGE+media+room+2.png' alt='Starship Captains in our media room' />
                        <Carousel.Caption>
                            <div className='GCslideTxt'>
                                <p>In 2021 we brought on 2 full time content creators to help expand our reach in the Board Game media space. To accomodate this I created a media space inside our exhibit room, which was very well recived.</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/Demo+Room.png' alt='Gen Con 2022 demo room' />
                        <Carousel.Caption>
                        <div className='GCslideTxt'>
                            <p>A look at the crowd enjoying our demo room. We had over 50 tables running and accomodated thousands of guests over the weekend, bringing large numbers into our room to push sales.</p>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/GiantGT1+1.png' alt='Giant Galaxy Trucker' />
                        <Carousel.Caption>
                        <div className='GCslideTxt'>
                            <p>Here's another look at Giant Galaxy Trucker. The giant games initiative is among my favorite projects I spearheaded at CGE. They are great show-stoppers that bring large crowds, and served to increase intrest in product from both those playing the giant versions and the people watching. </p>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/GTtealPin+1.png' alt='Gen Con 2022 pin design document' />
                        <Carousel.Caption>
                        <div className='GCslideTxt'>
                            <p>Every year we put out a new collectable pin for Gen Con. These pins have three benefits: they are a high profit item when sold on it's own, they offer a compelling promotional item that we gave free with high dollar purcheses, and they bring in pin collectors who may not normally be interested in our games. This is the final design document. I designed this pin based on existing artwork for Galaxy Trucker: Keep on Trucking, which was then polished to meet needed specs by our partner at Foam Brain. </p>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/Coupon+GC22+1.png' alt='Gen Con 2022 Coupon' />
                        <Carousel.Caption>
                            <div className='GCslideTxt'>
                                <p>Our add from the coupon book offering a free pin with a $50 spend. These coupons are very popular and both lead people to our room and increase their spend.</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/GC2022site+book+1.png' alt='Gen Con 22 site book ad' />
                        <Carousel.Caption>
                            <div className='GCslideTxt'>
                                <p>The site book ad from Gen Con 2022. We split our focus here between our new release at the show in Galaxy Trucker: Keep on Trucking and our hottest title Lost Ruins of Arnak and its expansion. We also made mention of the scavenger hunt we ran at the show.</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/GC22+big+banner+1.png' alt='Big banner at Gen Con 2022' />
                    <Carousel.Caption>
                            <div className='GCslideTxt'>
                                <p>This was our large banner outside of the main hall at Gen Con. This was another attempt to build brand recognition by featuring many of our top games in the same ad.</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/GenCon+row+markers.png' alt='Banners in main hall' />
                        <Carousel.Caption>
                            <div className='GCslideTxt'>
                                <p>As cosponsors of the show, we had our logo on alternating rows in the main hall.</p>
                            </ div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <p>Our video team also put together an overview of Gen Con 2022:</p>
            <div className='GC22videoWrapper'>
                <ReactPlayer
                    className='GC22video'
                    url='https://tylergoblebucket.s3.us-west-1.amazonaws.com/Gen+Con+2022/Gen+Con+2022+HIGHLIGHTS.mp4'
                    controls={true}
                    width='50vw'
                    height='100%'
                />
            </div>
            <div className='GC22bottomBorder' />
        </body>
    )
}
import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { StrictMode } from 'react';
import {createRoot } from 'react-dom/client' ;
import { BrowserRouter } from 'react-router-dom';
import Main from './Main';
import Nav from './Nav';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);



root.render(
  <StrictMode>
      <BrowserRouter>
          <Nav />
          <Main />
      </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();